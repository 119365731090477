.choice-text {
    color: black;
    height: 30px;
    border-width: 20px;
    border-radius: 7px;
    padding: 2px;
}

.choice-num {
    font-weight: bold;
    padding: 2px 5px;
}

.choice-button {
    width: fit-content;
    position: absolute;
}

.fan-format .choice-button {
    text-align: center;
}

.list-format .choice-rank-1 {
    top: 0;
}

.list-format .choice-rank-2 {
    top: 30px;
    z-index: -1;
}

.list-format .choice-rank-3 {
    top: 60px;
    z-index: -2;
}

.list-format .choice-rank-4 {
    top: 90px;
    z-index: -3;
}

.list-format .choice-rank-5 {
    top: 120px;
    z-index: -4;
}

.list-format .choice-rank-× {
    top: 150px;
    z-index: -10;
}

.fan-format .choice-rank-1 {
    top: -50px;
}

.fan-format .choice-rank-2 {
    left: -90px;
    top: -25px;
}

.fan-format .choice-rank-3 {
    left: 90px;
    top: -25px;
}

.fan-format .choice-rank-4 {
    left: -90px;
    top: 25px;
}

.fan-format .choice-rank-5 {
    left: 90px;
    top: 25px;
}

.fan-format .choice-rank-× {
    top: 72px;
}

.high-prob {
    background-color: #f90;
    border: solid #f90;
}

.mid-high-prob {
    background-color: #fc0;
    border: solid #fc0;
}

.mid-prob {
    background-color: #fe3;
    border: solid #fe3;
}

.mid-low-prob {
    background-color: #ff8;
    border: solid #ff8;
}

.low-prob {
    background-color: #ffc;
    border: solid #ffc;
}

.no-prob {
    background-color: #ccc;
    border: solid #ccc;
}