@mixin HeaderFont() {
  font-size: 3rem;
  font-weight: bolder;
  color: #000000;
}

@mixin pFont() {
  font-size: 1rem;
  color: #000000;
}
