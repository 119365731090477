@import "../../mixins/FontMixins";

.Home {
  height: 1vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;

  >h1 {
    @include HeaderFont();
  }

  >.model-response {
    @include pFont();
  }
}

.test-highlight {
  p, code {
    color: black;
  }

  textarea {
    color: white;
  }
}
