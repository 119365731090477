.Navbar {
  width: 100%;
  height: 3rem;

  >ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    list-style: none;
    gap: 2rem;

    >li {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 7rem;
      height: 90%;

      &:hover {
        cursor: pointer;
      }

      >a {
        width: 100%;
        height: 100%;
        text-align: center;
        vertical-align: center;
        background: linear-gradient(to bottom right, #00b1ff, #bb51d2);
        text-decoration: none;
      }
    }

  }
}

.footer {
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;

  >p {
    text-align: center;
    color: black;
  }
}
