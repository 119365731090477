@import "../../../mixins/FontMixins";

.CustomTextBox {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  >.ModifiedContent {
    color: #000000;
    width: 100%;
    min-width: 100%;
    text-wrap: wrap;

    >textarea {

    }
  }
}