*, *::before, *::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  font: inherit;
  //cursor: none;
}

img, picture, svg, video {
  display: block;
  max-width: 100%;
}

html {
  color-scheme: dark light;
  scroll-behavior: smooth;
}

:root {
  --background-angle: 106.5deg;
}

body {
  min-height: 100vh;
  min-width: 100vw;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffffff;
}

#root {
  min-height: 100vh;  // Needed for browser support.
  min-height: 100svh;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
